import * as React from "react";
import { AgendaItem, AgendaItemBreak } from "./AgendaItem";
import { AgendaSection } from "./AgendaSection";
import { AgendaMeeting } from "./AgendaMeeting";
export const Agenda = () => (
  <>
    <div>
      <div className="mt-6 py-12">
        <h4 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8">
          Plan spotkań w I połowie 2025
        </h4>
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-12 gap-y-4">
          <AgendaMeeting
            date="18 stycznia 2025"
            hours="08:00 - 10:00"
            place="Tychy"
          />
          <AgendaMeeting
            date="15 lutego 2025"
            hours="08:00 - 10:00"
            place="Tychy"
          />
          <AgendaMeeting
            date="29 marca 2025"
            hours="09:00 - 13:00"
            place="Tychy"
            comment={`Premiera książki Macieja Pieńkowskiego "Lean Gamification"`}
          />
          <AgendaMeeting
            date="26 kwietnia 2025"
            hours="08:00 - 10:00"
            place="Katowice"
          />
          <AgendaMeeting
            date="17 maja 2025"
            hours="08:00 - 10:00"
            place="Tychy"
          />
          <AgendaMeeting
            date="14 czerwca 2025"
            hours="08:00 - 10:00"
            place="Tychy"
          />
        </div>
      </div>
    </div>

    <AgendaSection
      title="Ostatnia Konferencja"
      date="28 września 2024"
      hours="08:30 - 13:30"
      place="Tychy"
      council={[
        "prof. dr hab. inż. Krzysztof Nowacki",
        "dr inż. Joanna Furman",
        "dr Wioletta Ocieczek",
        "dr inż. Tomasz Małysa",
      ]}
    >
      <AgendaItem time="8:30 - 9:00">
        Rejestracja uczestników. Otwarcie konferencji.
      </AgendaItem>

      <AgendaItem
        time="9:00 - 9:40"
        person="Henryk Metz"
        position="Expert / Project Manager"
      >
        Wpływ celu na wartość działania.
      </AgendaItem>

      <AgendaItem time="09:40 - 10:20">
        Panel warsztatowy (do wyboru)
        <br />
        <br />
        Panel 1: &nbsp; Jak kontrola czasów cyklu może zrewolucjonizować
        produkcję - Łukasz Felczyński
        <br />
        Panel 2: &nbsp; Narzędzia do wzmacniania kultury jakości w organizacji -
        Anna Farion
        <br />
        Panel 3: &nbsp; Audytowanie bez szkody dla Twoich codziennych obowiązków
        - Aleksander Niemczyk
      </AgendaItem>

      <AgendaItemBreak time="10:20 - 10:50" />

      <AgendaItem
        time="10:50 - 11:20"
        person="Jacek Trybalski"
        position="Plant Manager HDT Dynamic Technologies"
      >
        JIT oraz JIS - zalety i wady nowoczesnych systemów.
      </AgendaItem>

      <AgendaItem time="11:30 - 12:10">
        Panel warsztatowy (do wyboru)
        <br />
        <br />
        Panel 4: &nbsp; Technologie wspierające Lean - Piotr Bogucki
        <br />
        Panel 5: &nbsp; Industry 40 - Jak uzyskać wsparcie finansowe? -
        Magdalena Janiszewska
        <br />
        Panel 6: &nbsp; O czym zapominają liderzy? - Tomasz Flakiewicz
      </AgendaItem>

      <AgendaItemBreak time="12:10 - 12:40" />

      <AgendaItem
        time="12:40 - 13:10"
        person="Paweł Bugajski"
        position="Trener, Akademia Białego Kruka"
      >
        Six Sigma wzrost czy spadek?
      </AgendaItem>

      <AgendaItem time="13:15 - 13:30">
        Podsumowanie. Zamknięcie konferencji
      </AgendaItem>
    </AgendaSection>
    {/* <div className="mt-6 py-12">
      <h4 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8">
        Najbliższa Konferencja
      </h4>

      <p>
        Kolejna Edycja Konferencji Academy Smart Production odbędzie się 17
        czerwca 2023 roku (8:30 - 13:30) w Tychach. Więcej szczegółów i agenda
        wkrótce.
      </p>
    </div> */}
  </>
);
